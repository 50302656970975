import React, {Component} from "react";
import { Image} from "semantic-ui-react";
import {formatQuery} from "../controllers/utilities/string-utils";
import {connect} from "react-redux";
import KaisakuUserApi from "../controllers/kaisaku-user-api";
import {environmentMode} from "../components/lib/ad-manage-api-lib";
import iconGg from "../images/gg-logo.svg";
import Lambda from "../controllers/utilities/aws-lambda";
import {getFullPath} from "../controllers/kaisaku-api";
import cookie from "react-cookies";
import {navigate} from "gatsby";
import NavigationBar from "../components/forms/navigation-bar";

class GameSupport extends Component {
    constructor(props, context) {
        super(props, context);

        this.params = formatQuery(props.location.search);
        this.state = {
            ready: false,
            showback: false
        };
        const q = props.location.search;
        if ((q) && (q.indexOf("donwload") === -1) && (q.indexOf("newsafari") === -1)){
            console.log("显示");
            this.state.showback = true;
        }
    }


    async componentDidMount() {
        if(!window.Tawk_API) {
            window.Tawk_API = {};
        }
        const info = await this.gatherInfo();
        console.log(info);

        window.Tawk_API.onLoad = async () => {
            this.setState({ready: true});
            // window.Tawk_API.hideWidget();
            const info = await this.gatherInfo();
            console.log(info);
            window.Tawk_API.setAttributes({
                ...info
            }, err => {
                console.log(err);
            });
            window.Tawk_API.maximize();
            // window.Tawk_API.showWidget();
        };
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://embed.tawk.to/5dedfec643be710e1d212faf/default";
        if (environmentMode === "SANDBOX") {
            script.src = "https://tawk.to/chat/5f9faddfe019ee7748efeab5/default";
        }
        console.log(script.src);
        script.charset = "UTF-8";
        script.setAttribute("crossorigin", "*");
        document.body.appendChild(script);
    }

    async gatherInfo() {
        let data = await this.gatherInfo1();
        if (data){
            if ((!data.attributionId) && (this.params.attributionId)){
                data.attributionId = this.params.attributionId;
            }
        }
        return data;
    }

    async gatherInfo1() {
        if (!isValidSession(this.props)){
            let accessToken = cookie.load("_accessToken");
            // if (accessToken) {
                if (this.params.transactionId) {
                    return await gatherTransactionMeta(this.params.transactionId, accessToken);
                } else {
                    if (accessToken){
                        const res = await getProfile(accessToken);
                        return res.data;    
                    }else{
                        const data = { "gameId": this.params.gameId, "attributionId": this.params.attributionId, "userId": "-", "username": "未登陸用戶" + dateFormat(Date.now(), "yyyyMMddhhmmss")}
                        return data;
                    }
                }
            // }
        }else {
            if (this.params.transactionId) {
                return await new KaisakuUserApi(this.props.session).gatherTransactionMeta(this.params.transactionId);
            } else {
                const res = await new KaisakuUserApi(this.props.session).getProfile();
                return res.data;
            }
        }
    }

    render() {
        const mode = detectOrient();
        return (
            <React.Fragment>
 {(this.state.showback) && (<NavigationBar mode={mode} platform={"h5"} showLogo={1} showTitle={0} onBack={()=>{
                                // window.Tawk_API.hideWidget();
                                let backurl = cookie.load("csbackurl");
                                // console.log(backurl);
                                if (backurl){
                                    navigate(backurl);
                                }else{
                                    navigate("/confirm-purchase");
                                }
                            }}/>)}

            
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {session: state.session};
}

export default connect(mapStateToProps)(GameSupport);

async function gatherTransactionMeta(transactionId, accessToken) {
    const res = await Lambda.get(getFullPath("gatherCustomerServiceMeta"), {transactionId}, accessToken);
    return res.data;
}

export async function getProfile(accessToken) {
    return await Lambda.get(getFullPath("getProfile"), null, accessToken);
}

export async function getProfileApp(accessToken) {
    return await Lambda.get(getFullPath("getProfile"), { applogin: "1"}, accessToken);
}

function isValidSession(props) {
    if (!props) return false;
    return !!props.session && !!props.session.accessToken;
}

function dateFormat(time, fmt) { // author: meizz
    const date = new Date(time);
    // console.log(date);
    let o = {
        "M+": date.getMonth() + 1, // 月份
        "d+": date.getDate(), // 日
        "h+": date.getHours(), // 小时
        "m+": date.getMinutes(), // 分
        "s+": date.getSeconds(), // 秒
        "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
        "S": date.getMilliseconds() // 毫秒
    };
    if(/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for(let k in o)
        if(new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

function detectOrient() {
    if (typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    } else {
        return "PORTRAIT";
    }
}