import React, {Component} from "react";
import KaisakuApi, {getFullPath} from "../../../controllers/kaisaku-api";
import View from "./reset-password-form-view2";
import withTrans from "../../../i18n/withTrans";
import {formatQuery} from "../../../controllers/utilities/string-utils";
import Lambda from "../../../controllers/utilities/aws-lambda";
import cookie from "react-cookies";
import {getProfile} from "../../../pages/game-support";

class ResetPasswordForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            username: "",
            errorMsgs: [],
            loading: false,
            success: false
        };
    }

    onUsernameChange(e, data) {
        this.setState({username: data.value});
    }

    async onSubmit() {
        this.setState({
            loading: true,
            success: false,
            errorMsgs: []
        });
        console.log(this.props);
        const res = await KaisakuApi.resetPassword(this.state.username);
        this.processResult(res);
    }

    processResult(result) {
        if(result.success) {
            this.setState({
                loading: false,
                success: true
            });
        } else {
            this.setState({
                loading: false,
                success: result.success,
                errorMsgs: result.errorMsgs.map(this.props.t)
            });
        }
    }

    render() {
        return (
            <View
                loading={this.state.loading}
                success={this.state.success}
                errorMsgs={this.state.errorMsgs}
                onUsernameChange={this.onUsernameChange.bind(this)}
                onSubmit={this.onSubmit.bind(this)}
                q={this.props.q}
            />
        );
    }

}

export default withTrans(ResetPasswordForm);

function validatePassWd(password) {
    const str = password;
    if (str == null || str.length < 8 || str.length > 16) {
        return false;
    }
    const reg1 = new RegExp(/^[0-9A-Za-z]+$/);
    if (!reg1.test(str)) {
        return false;
    }
    const reg = new RegExp(/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/);
    if (reg.test(str)) {
        return true;
    } else {
        return false;
    }
}